<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController :showActionButtons="false" :order="order" title="Detalhes do Pedido">
      <GeneralData :order="order.general" />
      <Client :customer="order.customer" />
      <Freight :freightDetails="order.freightDetails" />
      <ItemService :items="order.items" :order="order.general" :freightDetails="order.freightDetails" />
      <Payment :items="order.payments" />
      <FiscalNote :items="order.fiscalNote" />
      <ShippingPackages :shippingPackages="order.shippingPackages" :shippingPackagesTotals="order.shippingPackagesTotals" />
      <template v-slot:endActions>
        <v-btn
          class="ml-4 form_step_controller_action_button"
          color="primary"
          outlined
          large
          @click="onClickCancelOrderModalHandler"
          v-if="form.status && form.status != statusOrder.cancelado"
        >
          CANCELAR PEDIDO
        </v-btn>
        <v-btn v-show="isEditing" class="ml-4 form_step_controller_action_button" color="primary" outlined large @click="onClickAttendanceHandler">
          SOLICITAR ATENDIMENTO
        </v-btn>
      </template>
    </FormStepController>
    <custom-modal-template
      :show="showAttendanceConfirmationDialog"
      :title="'Abertura de chamado'"
      :description="`Você deseja abrir um chamado para o Pedido ${form.number} ?`"
      :actionHandler="attendanceModalActionHandler"
    />
    <AlertModal :showModal="showModalCancelOrder">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3" v-if="wasApprovedBySellerAndAdmin">
            Uma vez cancelado o pedido, está ação não poderá ser desfeita.
          </div>
          <div class="mb-3" v-else>
            Um pedido só poderá ser cancelado após a abertura de um chamado de atendimento solicitando o cancelamento do pedido e que seja autorizado
            o cancelamento do pedido, tanto pelo seller quanto pelo atendente.
          </div>
          <div class="mb-3" v-if="wasApprovedBySellerAndAdmin">Deseja mesmo cancelar o pedido?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal" v-if="wasApprovedBySellerAndAdmin">CANCELAR</v-btn>
        <v-btn color="primary" @click="onClickCancelOrderHandler" :disabled="isProcessing" :loading="isProcessing" v-if="wasApprovedBySellerAndAdmin">SIM, CANCELAR PEDIDO</v-btn>
        <v-btn color="primary" @click="closeModal" v-else>OK, ENTENDIDO</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import moment from 'moment';
import utils from '@/utils/utils';
import FormStepController from '@/components/template/form/FormStepController';
import GeneralData from '@/views/order/detail/GeneralData';
import Client from '@/views/order/detail/Client';
import { get } from '../../../service/api';
import notification from '@/service/notification';
import { formatDateWithoutHour } from '@/utils/format-utils';
import { getSeller } from '@/service/seller/seller-service';
import { canCancelOrder, cancelOrder } from '@/service/order/order-service';
import { OrderStatus } from '@/static-data/OrderStatusOptions';
import Freight from '@/views/order/detail/Freight';
import ItemService from '@/views/order/detail/ItemService';
import Payment from '@/views/order/detail/Payment';
import FiscalNote from '@/views/order/detail/FiscalNote';
import { PaymentStatusOption } from '@/static-data/PaymentStatusOptions';
import { PaymentTypeOptions } from '@/static-data/PaymentTypeOptions';
import { GenderTypeOptions } from '@/static-data/GenderTypeOptions';
import { PersonTypeOptions, PersonDocumentTypeOptions } from '@/static-data/PersonTypeOptions';
import ShippingPackages from '@/views/order/detail/ShippingPackage';
import CustomModalTemplate from '../components/CustomModalTemplate';
import AlertModal from '@/components/modal/AlertModal';
import { statusOrder } from '../enum/StatusOrder';

export default {
  name: 'OrderDetail',
  data: () => ({
    showModalCancelOrder: false,
    wasApprovedBySellerAndAdmin: false,
    showAttendanceConfirmationDialog: false,
    paymentStatusOption: PaymentStatusOption,
    paymentTypeOptions: PaymentTypeOptions,
    GenderTypeOptions,
    isEditing: null,
    status: OrderStatus,
    statusOrder,
    form: {},
    order: {
      general: {},
      customer: {},
      freightDetails: {},
      items: [],
      payments: [],
      shippingPackages: [],
      shippingPackagesTotals: {
        uniqueProducts: '',
        totalProducts: '',
      },
      fiscalNote: [],
    },
    isProcessing: false,
  }),
  components: {
    ShippingPackages,
    Payment,
    ItemService,
    Freight,
    FormStepController,
    GeneralData,
    Client,
    CustomModalTemplate,
    AlertModal,
    FiscalNote,
  },
  mounted() {
    try {
      if (this.$route.params.number) {
        this.gerOrder(this.$route.params.number);
        this.isEditing = true;
      }
    } catch (error) {
      this.isEditing = false;
      console.error('Error on mount AdminOrderForm trying to get order', error);
    }
  },
  methods: {
    closeModal() {
      this.showModalCancelOrder = false;
      this.wasApprovedBySellerAndAdmin = false;
    },
    async onClickCancelOrderModalHandler() {
      this.showModalCancelOrder = true;
      this.isProcessing = false;
      try {
        this.wasApprovedBySellerAndAdmin = await canCancelOrder(this.form.number);
      } catch (error) {
        this.wasApprovedBySellerAndAdmin = false;
        notification('Erro ao tentar ver se o pedido pode ser cancelado', 'error');
      }
    },
    async onClickCancelOrderHandler() {
      if (this.wasApprovedBySellerAndAdmin) {
        try {
          this.isProcessing = true;
          const response = await cancelOrder(this.form.number);
          if (response.status >= 200 && response.status <= 300) {
            notification('Pedido cancelado com sucesso.', 'success');
            this.closeModal();
          } else {
            this.isProcessing = false;
            notification(`Erro ao cancelar pedido: ${response.response.data.message}`, 'error');
          }
        } catch (e) {
          this.isProcessing = false;
          notification('Erro ao cancelar pedido', 'error');
        }
      }
    },
    onClickAttendanceHandler() {
      this.showAttendanceConfirmationDialog = true;
    },
    setGeneralData(response) {
      this.order.general = {
        number: response.number,
        status: utils.getValueFromKey(this.status, response.status),
        total: Number(response.total).toFixed(2),
        sellerId: this.retrieveSellerName(response.sellerId),
        synchronizedAt: response.synchronizedAt,
        submittedAt: response.submittedAt,
      };
    },
    setClient(response) {
      const { customer } = response;
      try {
        this.order.customer = customer;
        this.order.customer = {
          ...this.order.customer,
          name: `${customer.firstName} ${customer.lastName}` || customer.companyName,
          birthday: customer.birthday ? formatDateWithoutHour(customer.birthday) : null,
          gender: customer.gender ? GenderTypeOptions[customer.gender.toLowerCase()] : null,
          personNameType: customer.personType ? PersonTypeOptions[customer.personType.toUpperCase()] : null,
          typeDocument: customer.personType ? PersonDocumentTypeOptions[customer.personType.toUpperCase()] : null,
        };
      } catch (e) {
        console.error('setClient', e);
      }
    },
    setFreight(response) {
      try {
        const total = response.freight - response.discount;
        this.order.freightDetails = {
          cost: response.freight.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          totalValue: total.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          discount: response.discount.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          tax: response?.tax?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) || '0,00',
          status: utils.getValueFromKey(this.status, response.status),
        };
      } catch (e) {
        console.error('setFreight', e);
      }
    },
    setItemServices(response) {
      this.order.items = response.items;
    },
    setPayments(response) {
      try {
        response.payments.forEach((payment) => {
          const type = payment.type ? utils.getValueFromKey(this.paymentTypeOptions, payment.type.toLowerCase()) : 'Outros';
          payment.type = type || 'Outros';
          const status = payment.status ? utils.getValueFromKey(this.paymentStatusOption, payment.status.toLowerCase()) || payment.status : '';
          payment.status = status || 'Desconhecido';
          payment.paidAt = payment.paidAt ? formatDateWithoutHour(payment.paidAt) : '';
        });
        this.order.payments = response.payments;
      } catch (e) {
        console.error('setPayments', e);
      }
    },
    setShippingPackages(response) {
      try {
        this.order.shippingPackages = response.shippingPackages;
        response.shippingPackages.forEach((shippingPkg) => {
          shippingPkg.status = utils.getValueFromKey(this.status, shippingPkg.status);
          shippingPkg.uniqueProducts = shippingPkg.itemRelationships.length || 0;
          shippingPkg.totalProducts = this.getTotalProducts(shippingPkg.itemRelationships);
          shippingPkg.separationDate = this.getSeparationDate(shippingPkg.separatedAt);
        });
      } catch (e) {
        console.error('setShippingPackages', e);
      }
    },
    setFiscalNote(response) {
      this.order.fiscalNote = [...response.shippingPackages
            .filter((pacote) => pacote.invoiceInfo !== null)
            .map((pacote) => pacote.invoiceInfo)];
    },
    getSeparationDate(separationInfo) {
      if (separationInfo === null) {
        return 'Não se aplica';
      }
      return moment(separationInfo).format('DD/MM/YYYY');
    },
    getTotalProducts(productsInPackage) {
      let totalItens = 0;
      productsInPackage.forEach((product) => {
        totalItens += product.quantity;
      });
      return totalItens;
    },
    retrieveSellerName(id) {
      getSeller(id)
        .then((response) => {
          this.order.general.sellerId = response.companyName;
        })
        .catch((error) => {
          notification('Erro ao buscar nome do Seller', 'error');
          return id;
        });
    },
    formatOrderDetails(response) {
      this.setGeneralData(response);
      if (response.customer) this.setClient(response);
      if (response.freight) this.setFreight(response);
      if (response.items) this.setItemServices(response);
      if (response.payments) this.setPayments(response);
      if (response.shippingPackages) this.setShippingPackages(response);
      this.setFiscalNote(response);
    },
    async gerOrder(id) {
      try {
        const response = await get(`ui-integrator/orders/${id}`);
        this.form = {
          ...response.data,
        };
        this.formatOrderDetails(this.form);
      } catch (e) {
        notification(e, 'error');
      }
    },
    attendanceModalActionHandler(action) {
      this.showAttendanceConfirmationDialog = false;
      if (action) {
        this.$router.push({ path: `/attendance/forms/order/${this.form?.number}` });
      }
    },
  },
};
</script>

<style scoped>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}
</style>
