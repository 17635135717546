export const ProdutoStatus = [
  { label: 'Publicado', value: 'PUBLISHED' },
  { label: 'Não publicado', value: 'UNPUBLISHED' },
];

export const ProdutoStatusOptions = {
  published: 'Publicado',
  unpublished: 'Não publicado',
};

export default ProdutoStatus;
