<template>
    <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
        <FormStepController
          title="Seller"
          v-on:formSubbmit="formSubbmit"
          :form="form"
          :steps="steps"
          alertScreenTitle="Cadastro Seller"
          backAlertMessage="Deseja cancelar o cadastro deste seller?">
            <GeneralInformationForm
              :form="form"
              :validationRules="steps['GeneralInformationForm'].rules"
              @validateRequired="validateRequired" />
            <AContactForm
              :content="form.contacts"
              v-on:addContact='addContact'
              v-on:onDeleteContact="deleteContact"
              :validationRules="steps['AContactForm'].rules" />
            <BankDataForm
              :form="form"
              :validationRules="steps['BankDataForm'].rules"
              @validateRequired="validateRequired" />
            <ConfigurationForm
              :form="form"
              :validationRules="steps['ConfigurationForm'].rules" />
        </FormStepController>
    </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import notification from '@/service/notification';
import GeneralInformationForm from '@/views/seller/forms/GeneralInformationForm';
import AContactForm from '@/views/seller/forms/AContactForm';
import BankDataForm from '@/views/seller/forms/BankDataForm';
import ConfigurationForm from '@/views/seller/forms/ConfigurationForm';
import { createSeller, updateSeller, getSeller } from '../../service/seller/seller-service';
import utils from '@/utils/utils';
import { routerPush } from '@/service/kplaceRouter';

export default {
  name: 'SellerForm',
  components: {
    FormStepController,
    GeneralInformationForm,
    AContactForm,
    BankDataForm,
    ConfigurationForm,
  },
  data() {
    return {
      editing: false,
      form: {
        originData: '',
        companyName: '',
        tradingName: '',
        marketplaceIdPrefix: '',
        marketplaceSlug: '',
        cnpj: '',
        activity: '',
        site: '',
        zipCode: '',
        soldAndDeliveredBy: '',
        integrationPlatform: '',
        contacts: [],
        bankAccount: {
          agency: '',
          bankCode: '',
          number: '',
        },
        allowsCommissionCalculation: false,
      },
      contact: {
        area: '',
        email: '',
        phone: '',
      },
      steps: {
        GeneralInformationForm: {
          step: 1,
          status: {},
          required: {
            companyName: true,
            tradingName: true,
            marketplaceIdPrefix: true,
            marketplaceSlug: true,
            cnpj: true,
            activity: true,
            site: true,
            zipCode: true,
            soldAndDeliveredBy: true,
            originData: true,
            integrationPlatform: true,
          },
          rules: {
            companyName: (value) => (value && value.length > 0) || 'Campo `Razão Social` é Obrigatório.',
            tradingName: (value) => (value && value.length > 0) || 'Campo `Nome fantasia` é Obrigatório.',
            marketplaceIdPrefix: (value) => (value && value.length > 0) || 'Campo `Prefixo` é Obrigatório.',
            marketplaceSlug: (value) => {
              const isValid = /^[a-z0-9\-_+]*$/.test(value);
              if (!value) return 'Campo `Marketplace Slug` é Obrigatório.';
              if (!isValid) return 'Somente é permitido letras minúsculas, underline, hífen e sinal de soma.';
              return true;
            },
            cnpj: (value) => (value && value.length > 0) || 'Campo `CNPJ` é Obrigatório.',
            activity: (value) => (value && value.length > 0) || 'Campo `Ramo de atividade` é Obrigatório.',
            site: (value) => (value && value.length > 0) || 'Campo `Site` é Obrigatório.',
            zipCode: (value) => (value && value.length > 0) || 'Campo `CEP` é Obrigatório.',
            soldAndDeliveredBy: (value) => (value && value.length > 0) || 'Campo `Vendido e entregue por` é Obrigatório.',
            originData: (value) => (value?.text && value.text.length > 0) || 'Campo `Origem de dados` é Obrigatório.',
            integrationPlatform: (value) => (value?.text && value.text.length > 0) || 'Campo `Plataformas de integração` é Obrigatório.',
          },
        },
        AContactForm: {
          step: 2,
          status: {},
          required: {},
          rules: {},
        },
        BankDataForm: {
          step: 3,
          status: {},
          required: {
            bankCode: true,
            agency: true,
            number: true,
          },
          rules: {
            bankCode: (value) => (value?.text && value.text.length > 0) || 'Campo `Banco` é Obrigatório.',
            agency: (value) => {
              if (!value) return 'Campo `Agência` é Obrigatório.';
              if (!this.validateAgencyOrAccount(value)) return 'Campo `Agência` aceita somente números e hífen';
              return true;
            },
            number: (value) => {
              if (!value) return 'Campo `Conta Corrente` é Obrigatório.';
              if (!this.validateAgencyOrAccount(value)) return 'Campo `Conta Corrente` aceita somente números e hífen';
              return true;
            },
          },
        },
        ConfigurationForm: {
          step: 4,
          status: {},
          required: {},
          rules: {},
        },
      },
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      const seller = await getSeller(this.$route.params.id);
      this.form = seller;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    } else {
      this.editing = false;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },
  methods: {
    formSubbmit() {
      this.save();
    },
    async save() {
      try {
        let data;
        if (this.form.id) {
          data = await updateSeller(this.form);
        } else {
          data = await createSeller(this.form);
        }
        if (data?.status >= 200 && data?.status <= 300) {
          notification('O cadastro do seller foi salvo com sucesso', 'success');
          routerPush('/sellers');
        } else {
          const message = data.data.errors
            ? `${data.data.message} ${data.data.errors[0]}`
            : `${data.data.message}`;
            notification(`${message}`, 'error');
        }
      } catch (e) {
        notification(`Erro: ${e}`, 'error');
      }
    },
    addContact(contactNew) {
      this.contact.email = contactNew.email;
      this.contact.phone = contactNew.phone;
      this.contact.area = contactNew.area.value;
      this.form.contacts.push(this.contact);
      this.contact = {};
    },
    deleteContact(contactOld) {
      const index = this.form.contacts.indexOf(contactOld);
      this.form.contacts.splice(index, 1);
    },
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);
      utils.revalidate(this);
    },
    validateAgencyOrAccount(number) {
      return /^[\d-]+$/.test(number);
    },
  },
};
</script>

<style>
    .box-content {
        background-color: rgb(237, 237, 237);
        height: calc(100% - 80px);
        margin-top: 80px
    }
</style>
