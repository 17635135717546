import { render, staticRenderFns } from "./AttendanceForm.vue?vue&type=template&id=672e499c"
import script from "./AttendanceForm.vue?vue&type=script&lang=js"
export * from "./AttendanceForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VForm,VSkeletonLoader,VTextarea})
