<template>
  <FormContent :selected="selected" class="pa-6">
    <div class="margin-fix pa-3 center-items">
      <SwitchInput
        class="ma-0"
        label="Permite calculo de comissão"
        placeHolder="Não"
        v-model="form.allowsCommissionCalculation"
        labelWidth="25%"
      />
    </div>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import SwitchInput from '@/components/template/form/input/SwitchInput';

export default {
  name: 'ConfigurationForm',
  components: {
    FormContent,
    SwitchInput,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Configurações',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: false,
    };
  },
};
</script>

<style>
.center-items {
  display: flex;
  align-items: center;
}
</style>
